@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.menuDesktop {
  position: absolute;
  top: 72px;
  width: 256px;

  &.close {
    @include fadeOut;
  }

  &.open {
    @include fadeIn;
  }
}

.menuMobile {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  position: fixed;
  top: 0;
  height: 100%;
  width: 70%;
  z-index: $zIndexMobileMenu;

  @include breakpoint-sm-up {
    max-width: 250px;
  }

  &.close {
    @include hideRighToLeft;
  }
  &.open {
    @include showLeftToRight;
  }
}

.menu {
  background-color: #ffffff;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  overflow: auto;

  &.logoTop {
    margin-top: 10px;
  }

  &.logoBottom {
    margin-left: 12px;
  }
}

.backdrop {
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  height: 100vh;
  width: 100vw;
  z-index: $zIndexMobileMenuBackDrop;
}

.welcomeUser {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.menuList {
  border-radius: 4px;
  list-style: none;
  padding: 0 24px 24px;

  &.open {
    margin: 24px 0 0;
  }

  @include breakpoint-md-up {
    padding: 24px;
    margin: 0;

    &.open {
      margin: 0;
    }
  }
}

.menuItem {
  margin-bottom: 16px;

  &:first-child {
    margin-bottom: 20px;
  }

  a {
    display: flex;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.bold {
  font-weight: 700;
}
.itemWithicon {
  display: flex;
  align-items: center;
  gap: 10px;
}
