@import "~@lc/ui/styles/settings/_breakpoints.scss";
@import "~@lc/ui/styles/settings/_colors.scss";
@import "~@lc/ui/styles/settings/_variables.scss";

.modalcontent {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  margin-top: -10px;

  p {
    padding: 0 26px;
    width: 300px;
  }
  .expertiseList {
    width: 300px;
  }
}

.nav {
  align-items: center;
  background-color: #ffffff;
  bottom: 0;
  box-sizing: content-box;
  display: flex;
  box-shadow: 0px -1px 2px rgb(0 0 0 / 10%), 0px -8px 16px rgb(0 0 0 / 20%);
  justify-content: space-around;
  height: 40px;
  padding: 12px 0;
  position: sticky;
  z-index: $zIndexBottomMenu;

  @include breakpoint-md-up {
    display: none;
  }

  .logo {
    height: 50px;
    width: 35px;
  }

  .item {
    align-items: center;
    display: flex;
    flex-direction: column;

    svg {
      font-size: 20px;
      height: 30px;
    }
  }
  .active {
    color: $primary-blue;
    svg {
      font-size: 30px;
    }
  }
}
